import React from "react";

const Hero = () => {
  return (
    <main className=" flex justify-center capitalize gap-4 sm:justify-between sm:flex-row flex-col relative items-center">
      <img
        src="/adminLogo.png"
        className=" w-20 md:w-24 lg:w-32 xl:w-56 h-auto"
        alt="logo"
      />
      <h3 className="text-2xl sm:hidden capitalize sm:text-3xl xl:text-5xl font-extralight text-center text-[#707070]">
      Platzieren Sie jetzt Ihr Gebot
      </h3>
      <section className="max-w-screen-xl hidden sm:flex absolute top-0 left-0 right-0 justify-center items-center bottom-0 mx-auto ">
        <h3 className="text-2xl sm:text-3xl capitalize xl:text-5xl font-extralight text-center text-[#707070]">
        Platzieren Sie jetzt Ihr Gebot
        </h3>
      </section>
    </main>
  );
};

export default Hero;
