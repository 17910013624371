import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import AdminDashboard from "./components/pages/AdminDashboard";
import Dashboard from "./components/admin/Dashboard";
import SoldItems from "./components/admin/SoldItems";
import CanceledItems from "./components/admin/CanceledItems";
import { ToastContainer } from "react-toastify";
import { getAllItems, getAllBids, backend_base } from "./libs/backendAPIs";
import { useEffect, useState } from "react";
import { useItemStore } from "./store/useItemStore";
import io from "socket.io-client"; // Import Socket.IO client
import { useBidStore } from "./store/useBidStore";
import "react-toastify/dist/ReactToastify.css";
import BidDetailMOdal from "./components/admin/BidDetailModal";
import AdminLogin from "./components/pages/AdminLogin";
// Define socket connection
const socket = io(backend_base);
function App() {
  const {
    updateLiveItems,
    liveItems,
    soldItems,
    updateSoldItems,
    canceledItems,
    updateCanceledItems,
  } = useItemStore();
  const {
    updateAllBids,
    allBids,
    allBidsByLiveItemID,
    updateAllBidsByLiveItemID,
  } = useBidStore();
  let [isBidDMOpen, setBidDMOpen] = useState(false);
  const [itemIdForBidsModal, setItemIdForBidsModal] = useState();
  let [isLoginOpen, setIsLoginOpen] = useState(
    JSON.parse(localStorage.getItem("adminLogin"))
  );
  const fetchBackendData = async () => {
    const allItems = await getAllItems();
    const liveitems = allItems?.items?.filter((item) => item.status === "live");
    const solditems = allItems?.items?.filter((item) => item.status === "sold");
    const cancelitems = allItems?.items?.filter(
      (item) => item.status === "cancel"
    );
    console.log("liveItems", liveitems);
    // console.log("soldItems", solditems);
    // console.log("cancelItems", cancelitems);
    updateLiveItems(liveitems);
    updateSoldItems(solditems);
    updateCanceledItems(cancelitems);
    const Bids = await getAllBids();
    updateAllBids(Bids?.bids);
    if (liveitems.length > 0) {
      const Bids = await getAllBids();
      console.log(Bids);
      const data = Bids?.bids?.filter(
        (item) => item.itemId === liveitems[0]?.id
      );
      updateAllBidsByLiveItemID(data);
    }
  };
  useEffect(() => {
    const asyncCall = async () => {
      await fetchBackendData();
    };
    // Listen for 'newItem' event from the server
    socket.on("newItem", (newItem) => {
      console.log("New item added:", newItem);
      updateLiveItems([newItem]);
    });
    // Listen for 'newBid' event from the server
    socket.on("newBid", (newBid) => {
      console.log("New item added:", newBid);
      asyncCall();
      // allBidsByLiveItemID?.length > 0
      //   ? updateAllBidsByLiveItemID([...allBidsByLiveItemID, newBid])
      //   : updateAllBidsByLiveItemID([newBid]);
      // allBids?.length > 0
      //   ? updateAllBids([...allBids, newBid])
      //   : updateAllBids([newBid]);
    });
    // Listen for 'updateItemByStatus' event from the server
    socket.on("updateItemByStatus", (item) => {
      liveItems.splice(0, 1);
      updateLiveItems(liveItems);
      updateAllBidsByLiveItemID([]);
      // console.log(item);
      // // Add the incoming item at the 0 index of the liveItems array
      // if (item.status === "sold") {
      //   soldItems?.length > 0
      //     ? updateSoldItems([...soldItems, item])
      //     : updateSoldItems([item]);
      // } else {
      //   canceledItems?.length > 0
      //     ? updateCanceledItems([...canceledItems, item])
      //     : updateCanceledItems([item]);
      // }

      asyncCall();
    });
    // Listen for 'updateItem' event from the server
    socket.on("updateItem", (item) => {
      console.log("Incoming item:", item);

      // Remove the first item from the liveItems array
      liveItems.splice(0, 1);

      // Add the incoming item at the 0 index of the liveItems array
      liveItems.unshift(item);

      // console.log("Updated liveItems array:", liveItems);

      // Update the state with the modified liveItems array
      updateLiveItems([...liveItems]);
    });
    // Listen for 'closeBid' event from the server
    socket.on("closeBid", (item) => {
      console.log(item);
      liveItems.splice(0, 1);
      console.log(liveItems);
      updateAllBidsByLiveItemID([]);
      updateLiveItems(liveItems);
    });
    // Listen for 'updatedBidTime' event from the server
    socket.on("updatedBidTime", (item) => {
      // console.log("Received updatedBidTime event");
      // console.log("Incoming item:", item);

      // Remove the first item from the liveItems array
      liveItems.splice(0, 1);

      // Add the incoming item at the 0 index of the liveItems array
      liveItems.unshift(item);

      // console.log("Updated liveItems array:", liveItems);

      // Update the state with the modified liveItems array
      updateLiveItems([...liveItems]);
    });
    // Listen for 'updateTimer' event from the server
    socket.on("updateTimer", (item) => {
      console.log("Received updateTimer event");
      console.log("Incoming item:", item?.bidTime);

      // Remove the first item from the liveItems array
      liveItems.splice(0, 1);

      // Add the incoming item at the 0 index of the liveItems array
      liveItems.unshift(item);

      // console.log("Updated liveItems array:", liveItems);

      // Update the state with the modified liveItems array
      updateLiveItems([...liveItems]);
    });
    asyncCall();
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/admin"
          element={
            !isLoginOpen ? (
              <AdminLogin
                isLoginOpen={isLoginOpen}
                setIsLoginOpen={setIsLoginOpen}
              />
            ) : (
              <AdminDashboard
                isLoginOpen={isLoginOpen}
                setIsLoginOpen={setIsLoginOpen}
              />
            )
          }
        >
          <Route
            path="/admin"
            element={
              <Dashboard
                isBidDMOpen={isBidDMOpen}
                setBidDMOpen={setBidDMOpen}
                itemIdForBidsModal={itemIdForBidsModal}
                setItemIdForBidsModal={setItemIdForBidsModal}
              />
            }
          />
          <Route
            path="soldItems"
            element={
              <SoldItems
                isBidDMOpen={isBidDMOpen}
                setBidDMOpen={setBidDMOpen}
                itemIdForBidsModal={itemIdForBidsModal}
                setItemIdForBidsModal={setItemIdForBidsModal}
              />
            }
          />
          <Route path="canceledItems" element={<CanceledItems />} />
        </Route>
      </Routes>
      <BidDetailMOdal
        isBidDMOpen={isBidDMOpen}
        setBidDMOpen={setBidDMOpen}
        itemIdForBidsModal={itemIdForBidsModal}
        setItemIdForBidsModal={setItemIdForBidsModal}
      />
      <ToastContainer />
    </Router>
  );
}

export default App;
