import React, { useEffect, useState } from "react";
import { useItemStore } from "../../store/useItemStore";
import { addBid, image_base } from "../../libs/backendAPIs";
import { Formik, Form, ErrorMessage, FastField } from "formik";
import * as Yup from "yup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  FaAngleDown,
  FaAngleUp,
  FaArrowDown,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { useBidStore } from "../../store/useBidStore";
import NoData from "../reuse/NoData";
import { IoIosArrowDown } from "react-icons/io";
const Item = () => {
  const { liveItems, updateLiveItems } = useItemStore();
  const [addBidState, setAddBidState] = useState(false);
  const { allBidsByLiveItemID } = useBidStore();
  const [isDMOpen, setIsDMOpen] = useState(false);
  const [selectedDMOptions, setSelectedDMOptions] = useState([]);
  const [dmError, setDmError] = useState("");
  const options = [
    "Ich/ wir haben die entsprechenden Kaufunterlagen von David Deindl Immobilien erhalten.",
    "Ich/ wir hatten ausreichen Zeit die Immobilie zu besichtigen.",
    "Mir/ uns ist bewusst, dass ich/ wir vor Abgabe eines Gebots die Klärung zur Finanzierung abgeschlossen haben sollte(n). Erforderliche Unterlagen stellt das David Deindl Immobilien GmbH zur Verfügung.",
    "Mir/ uns ist klar, dass dieses Angebot für den Verkäufer nicht bindend ist. Der Verkäufer hat die Möglichkeit, auch das Höchstangebot auszuschlagen, wenn ein seinen Vorstellungen entsprechender Mindestpreis nicht erzielt wird (Auftraggeber-Zustimmung vorbehalten). Dieses Gebot wird ferner nur weitergegeben, wenn es das Höchstgebot ist. Sollte der Verkäufer das Höchstgebot ausschlagen, erhält jeder angemeldete Biet Interessent das aktuelle Höchstgebot mitgeteilt und er kann ein neues Kaufpreisangebot abgeben.",
    "Die Herbeiführung des Vertragsabschlusses durch die David Deindl Immobilien GmbH ist eine provisionspflichtige Maklertätigkeit. Mir/ uns ist bekannt, dass bei Zustandekommen eines notariellen Kaufvertrages für die angebotene Immobilie dem Makler eine ortsübliche Provision in Höhe von 3,6% inkl. 20% MwSt des Kaufpreises durch den Käufer zu zahlen ist.",
    "Die Grundlage für die Gebotsabgabe ist das in der Erst-Mail beigefügte Kaufanbot.",
  ];
  const toggleDropdown = () => setIsDMOpen(!isDMOpen);

  const handleCheckboxChange = (option) => {
    setSelectedDMOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    bidPrice:
      allBidsByLiveItemID?.length > 0
        ? parseInt(
            allBidsByLiveItemID[allBidsByLiveItemID.length - 1]?.bidPrice
          ) + parseInt(liveItems[0]?.priceGap)
        : liveItems[0]?.bidPrice,
  });
  console.log(allBidsByLiveItemID);
  useEffect(() => {
    setInitialValues({
      name: "",
      email: "",
      bidPrice:
        allBidsByLiveItemID?.length > 0
          ? parseInt(
              allBidsByLiveItemID[allBidsByLiveItemID.length - 1]?.bidPrice
            ) + parseInt(liveItems[0]?.priceGap)
          : liveItems[0]?.bidPrice,
    });
  }, [allBidsByLiveItemID]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    bidPrice: Yup.number()
      .required("Bid price is required")
      .test(
        "greater-than-highest-price",
        "Bid price must be greater than Highest price",
        function (value) {
          const livePrice =
            allBidsByLiveItemID.length > 0 &&
            parseInt(allBidsByLiveItemID[0]?.bidPrice);
          return livePrice
            ? value > livePrice
            : (value) => liveItems[0]?.bidPrice;
        }
      ),
  });

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("bid"));
    console.log(data);
    console.log(allBidsByLiveItemID[allBidsByLiveItemID.length - 1]);
    if (
      data &&
      allBidsByLiveItemID?.length > 0 &&
      data?.email === allBidsByLiveItemID[allBidsByLiveItemID.length - 1]?.email
    ) {
      setAddBidState(true);
    } else {
      setAddBidState(false);
    }
  }, [allBidsByLiveItemID]);
  // console.log(isSubmitting)
  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    if (selectedDMOptions.length !== options.length) {
      setDmError("Please select all options.");
      setSubmitting(false);
      return;
    }
    setDmError("");
    try {
      setSubmitting(true);
      const res = await addBid({
        ...values,
        itemId: liveItems[0]?.id,
        preBidId: allBidsByLiveItemID[0]?.id,
      });
      console.log(res);
      if (res?.status === 200) {
        toast.success("Gebot erfolgreich hinzugefügt", {
          position: "top-center",
        });
        localStorage.setItem("bid", JSON.stringify(values));
        setAddBidState(true);
      }
      setIsDMOpen(false);
      resetForm();
    } catch (error) {
      toast.error("Failed to add bid. Please try again later.", {
        position: "top-center",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const formatBidTime = (bidTimeStr) => {
    const [days, hours, mins, secs] = bidTimeStr.split(":").map(Number);
    const formattedBidTime = [];

    if (days > 0) formattedBidTime.push(`${days} Tag${days > 1 ? "e" : ""}`);
    if (hours > 0)
      formattedBidTime.push(`${hours} Stunde${hours > 1 ? "n" : ""}`);
    if (mins > 0) formattedBidTime.push(`${mins} Minute${mins > 1 ? "n" : ""}`);
    if (secs > 0)
      formattedBidTime.push(`${secs} Sekunde${secs > 1 ? "n" : ""}`);

    return formattedBidTime.join(" ");
  };

  return (
    <>
      {liveItems?.length > 0 && (
        <section className=" flex flex-col gap-5 md:gap-8 my-16 justify-center items-center">
          <section className="max-w-screen-xl relative  bg-white px-4 py-2 md:pb-56 sm:py-8 sm:px-16 mx-4 md:mx-16 min-[1900px]:mx-auto ">
            <section className="grid grid-cols-1 pb-32 lg:grid-cols-2 gap-2 md:gap-4 xl:gap-8">
              <div className="flex w-full  md:gap-8 gap-2  justify-between flex-col items-start">
                <h3 className="text-2xl md:text-5xl 2xl:text-7xl font-bold">
                  {liveItems[0]?.title}
                </h3>
                <p className="text-base md:text-lg text-justify whitespace-pre-wrap">
                  {liveItems[0]?.description}
                </p>
                <h5 className="text-xl md:text-3xl flex flex-col gap-2 md:text-medium font-bold">
                  Verbleibende Gebotszeit:
                  <span className=" mx-3 text-[#94282B] text-2xl md:text-4xl font-light">
                    {formatBidTime(liveItems[0]?.bidTime)}
                  </span>
                </h5>
                <h5 className="text-xl md:text-3xl flex flex-col gap-2 md:text-medium font-bold">
                  Mindestgebotspreis:
                  <span className=" mx-3 text-[#17913A] text-2xl md:text-4xl font-light">
                    €
                    {new Intl.NumberFormat("en-US").format(
                      liveItems[0]?.bidPrice
                    )}
                  </span>
                </h5>
              </div>
              <div className=" flex flex-col gap-2 md:gap-4 justify-start items-end">
                <img
                  alt="content"
                  class="object-contain object-center h-full w-full"
                  src={image_base + "/" + liveItems[0]?.image}
                />
                <h5 className="text-md md:text-xl w-full flex flex-col sm:flex-row sm:items-end gap-2 md:text-medium font-bold">
                  Derzeit höchstes Gebot:
                  <span className="  text-[#17913A] text-2xl md:text-4xl truncate font-light">
                    {allBidsByLiveItemID.length > 0
                      ? `€${new Intl.NumberFormat("en-US").format(
                          allBidsByLiveItemID[allBidsByLiveItemID?.length - 1]
                            ?.bidPrice
                        )}`
                      : "keine Gebot"}
                  </span>
                </h5>
              </div>
            </section>
          </section>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="lg:w-1/2 2xl:w-[25%] relative w-[80%]  bg-[#98A3A6] z-30 -mt-20 md:-mt-56 p-8 mx-auto rounded-md shadow-md">
                {addBidState && (
                  <h2 className=" text-red-500 z-50 w-full text-center">
                    Ihr Gebot ist derzeit das höchste
                  </h2>
                )}
                {addBidState && (
                  <div className=" absolute top-0 left-0 right-0 bottom-0 z-10"></div>
                )}
                <div className="relative z-0 w-full mb-5 group">
                  <FastField
                    type="text"
                    name="name"
                    id="name"
                    className="block py-2.5 px-0 capitalize w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500"
                  />
                  <label
                    htmlFor="name"
                    className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Name
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <FastField
                    type="email"
                    name="email"
                    id="email"
                    className="block py-2.5 px-0 capitalize w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />
                  <label
                    htmlFor="email"
                    className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    E-Mail-Adresse
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <FastField
                    type="text"
                    name="bidPrice"
                    id="bidPrice"
                    className="block py-2.5 px-0 capitalize w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                    placeholder=""
                  />
                  <div className="flex flex-col gap-3 w-12 cursor-pointer justify-center font-bold text-white absolute right-0 -top-1 rounded-xl items-center py-2">
                    <FaPlus
                      onClick={() => {
                        setFieldValue(
                          "bidPrice",
                          parseInt(values?.bidPrice) +
                            parseInt(liveItems[0]?.priceGap)
                        );
                      }}
                      size={10}
                      className="hover:text-gray-500"
                    />
                    <FaMinus
                      onClick={() => {
                        const newBidPrice =
                          parseInt(values?.bidPrice) -
                          parseInt(liveItems[0]?.priceGap);
                        if (newBidPrice > 0) {
                          setFieldValue("bidPrice", newBidPrice);
                        }
                      }}
                      className="hover:text-gray-500"
                      size={10}
                    />
                  </div>
                  <ErrorMessage
                    name="bidPrice"
                    component="div"
                    className="text-red-500"
                  />
                  <label
                    htmlFor="bidPrice"
                    className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Angebotspreis
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    className="block relative capitalize py-2.5 px-0 w-full text-left text-sm text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                  >
                    Bedingungen akzeptieren
                    <div className="w-12 cursor-pointer flex justify-center font-bold text-white absolute right-0 top-0 bottom-0 rounded-xl items-center">
                      {isDMOpen ? (
                        <FaAngleUp
                          size={15}
                          color="white"
                          className="hover:text-gray-500"
                        />
                      ) : (
                        <FaAngleDown
                          size={15}
                          onClick={toggleDropdown}
                          color="white"
                          className="hover:text-gray-500"
                        />
                      )}
                    </div>
                  </button>
                  {isDMOpen && (
                    <div className="w-full mt-1">
                      {options.map((option, index) => (
                        <div key={index} className="flex items-center p-2">
                          <input
                            type="checkbox"
                            id={`option-${index}`}
                            checked={selectedDMOptions.includes(option)}
                            onChange={() => handleCheckboxChange(option)}
                            className="form-checkbox text-indigo-600 transition duration-150 ease-in-out"
                          />
                          <label
                            htmlFor={`option-${index}`}
                            className="ml-2 block text-sm leading-5 text-white"
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                  {dmError && (
                    <div className="text-red-500 text-sm mt-2">{dmError}</div>
                  )}
                </div>
                <section className="flex justify-center items-center max-w-screen-xl mx-auto">
                  <button
                    type="submit"
                    disabled={addBidState || isSubmitting}
                    className={`text-[#707070] ${
                      addBidState ? "bg-gray-300" : "bg-gray-200"
                    } bg-gray-200 mx-auto hover:bg-gray-300 focus:ring-4 outline-none font-medium text-sm px-12 py-2.5 text-center`}
                  >
                    {isSubmitting ? (
                      <AiOutlineLoading3Quarters
                        color={"white"}
                        size={28}
                        className="animate-spin"
                      />
                    ) : (
                      "Einreichen"
                    )}
                  </button>
                </section>
              </Form>
            )}
          </Formik>
        </section>
      )}
    </>
  );
};

export default Item;
