import React from "react";
import AdminLoginModal from "../admin/AdminLoginModal";
const AdminLogin = ({ isLoginOpen, setIsLoginOpen }) => {
  return (
    <>
      <main className=" flex justify-center capitalize gap-4 sm:justify-between sm:flex-row flex-col relative items-center">
        <img
          src="/adminLogo.png"
          className=" w-20 md:w-24 lg:w-32 xl:w-56 h-auto"
          alt="logo"
        />
      </main>
      <AdminLoginModal
        isLoginOpen={isLoginOpen}
        setIsLoginOpen={setIsLoginOpen}
      />
    </>
  );
};

export default AdminLogin;
