import axios from "axios";

// export const backend_base = "http://localhost:10000";
export const backend_base = "https://auction-backend-0it7.onrender.com";
export const image_base = `${backend_base}/uploads`;

export const addItem = async (values) => {
  try {
    // Create a new FormData object
    const formData = new FormData();

    // Append each key-value pair from the values object to the FormData
    Object.keys(values).forEach((key) => {
      // If the value is a file, append it with the corresponding key
      if (key === "image") {
        formData.append("image", values[key]);
      } else {
        // Otherwise, append it as a regular field
        formData.append(key, values[key]);
      }
    });

    // Send the FormData to the backend API
    const response = await axios.post(`${backend_base}/api/addItem`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
      },
    });

    return response?.data;
  } catch (error) {
    console.log("there are errors in loading data", error);
  }
};
export const getAllItems = async () => {
  try {
    const response = await axios.get(`${backend_base}/api/getAllItems`);
    return response?.data;
  } catch (error) {
    console.log("there are errors in loading data", error);
  }
};
export const addBid = async (values) => {
  try {
    // Send a POST request to the backend API with the Bid data
    const response = await axios.post(`${backend_base}/api/addBid`, values);
    return response;
  } catch (error) {
    console.log("Error adding Bid:", error);
    throw error; // Re-throw the error for handling in the UI
  }
};
export const updateItemByFieldName = async (values) => {
  try {
    // Send a POST request to the backend API with the status update data
    const response = await axios.post(
      `${backend_base}/api/updateItemByFieldName`,
      values
    );
    return response?.data;
  } catch (error) {
    console.log("Error updating item status:", error);
    throw error; // Re-throw the error for handling in the UI
  }
};
// export const updateItem = async (values, id) => {
//   try {
//     // Create a new FormData object
//     const formData = new FormData();
//     console.log(values)
//     // Append each key-value pair from the values object to the FormData
//     Object.keys(values).forEach((key) => {
//       // If the value is a file, append it with the corresponding key
//       if (key === "image") {
//         formData.append("image", values[key]);
//       } else {
//         // Otherwise, append it as a regular field
//         formData.append(key, values[key]);
//       }
//     });

//     // Send the FormData to the backend API
//     const response = await axios.post(
//       `${backend_base}/api/updateItem/${id}`,
//       formData,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
//         },
//       }
//     );

//     return response?.data;
//   } catch (error) {
//     console.log("there are errors in loading data", error);
//   }
// };

export const updateItem = async (values, id) => {
  try {
    const formData = new FormData();

    // Append each key-value pair from the values object to the FormData
    Object.keys(values).forEach((key) => {
      if (key === "image") {
        formData.append("image", values[key]);
      } else {
        formData.append(key, values[key]);
      }
    });

    // Use fetch to send the FormData to the backend API
    const response = await fetch(`${backend_base}/api/updateItem/${id}`, {
      method: "POST",
      body: formData,
      headers: {
        // 'Content-Type': 'multipart/form-data', // Do not set Content-Type header; fetch will automatically set it to multipart/form-data
      },
    });

    // Parse the JSON response
    const result = await response.json();

    return result;
  } catch (error) {
    console.log("There are errors in loading data", error);
  }
};

export const getAllBids = async () => {
  try {
    // Send a GET request to the backend API to fetch all Bids for a specific item
    const response = await axios.get(`${backend_base}/api/getAllBids`);
    return response?.data;
  } catch (error) {
    console.log("Error getting Bids by item ID:", error);
    throw error; // Re-throw the error for handling in the UI
  }
};
