import React from "react";

const NoData = ({ type }) => {
  return (
    <section className=" flex justify-center items-center h-screen mx-auto w-1/2 max-w-screen-xl">
      <h3 className="text-lg md:text-xl font-semibold capitalize">keine  {type}</h3>
    </section>
  );
};

export default NoData;
