import { create } from "zustand";

export const useItemStore = create((set) => ({
  liveItems: [],
  soldItems: [],
  canceledItems: [],
  updateLiveItems: (data) => set({ liveItems: data }),
  updateSoldItems: (data) => set({ soldItems: data }),
  updateCanceledItems: (data) => set({ canceledItems: data }),
}));
