import React, { useEffect, useState } from "react";
import NoData from "../reuse/NoData";
import { useItemStore } from "../../store/useItemStore";
import { image_base } from "../../libs/backendAPIs";
import { Link, useNavigate } from "react-router-dom";
import { useBidStore } from "../../store/useBidStore";
import { MdArrowDropDown } from "react-icons/md";

const SoldItems = ({
  setBidDMOpen,
  isBidDMOpen,
  itemIdForBidsModal,
  setItemIdForBidsModal,
}) => {
  const navigate = useNavigate();
  const { soldItems } = useItemStore();
  const { allBids } = useBidStore();
  const [allItemsData, setAllItemsData] = useState();
  const [sortAscending, setSortAscending] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  useEffect(() => {
    if (soldItems && allBids) {
      const updatedAllItemsData = soldItems.map((item) => {
        const itemBids = allBids.filter((bid) => bid.itemId === item.id);
        // Sort by bidPrice descending, then by createdAt ascending
        itemBids.sort((a, b) => {
          if (parseInt(b.bidPrice) === parseInt(a.bidPrice)) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          }
          return parseInt(b.bidPrice) - parseInt(a.bidPrice);
        });
        return {
          ...item,
          bids: itemBids,
        };
      });

      setAllItemsData(sortItems(updatedAllItemsData, sortAscending));
    }
  }, [soldItems, allBids, sortAscending]);
  const sortItems = (items, sortAscending) => {
    return items.sort((a, b) => {
      if (sortAscending === 1) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else if (sortAscending === 2) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else if (sortAscending === 3) {
        const aLastBid =
          a.bids.length > 0 ? a.bids[a.bids.length - 1].bidPrice : 0;
        const bLastBid =
          b.bids.length > 0 ? b.bids[b.bids.length - 1].bidPrice : 0;
        return parseInt(bLastBid) - parseInt(aLastBid);
      } else if (sortAscending === 4) {
        const aLastBid =
          a.bids.length > 0 ? a.bids[a.bids.length - 1].bidPrice : 0;
        const bLastBid =
          b.bids.length > 0 ? b.bids[b.bids.length - 1].bidPrice : 0;
        return parseInt(aLastBid) - parseInt(bLastBid);
      } else {
        return 0;
      }
    });
  };

  console.log(allItemsData);
  return (
    <section className="flex capitalize flex-col justify-center items-center gap-6">
      <section className="">
        <section className="relative flex justify-center gap-12 flex-col sm:flex-row items-center w-full md:pr-8 px-4 md:pl-72 my-16">
          <h3 className="text-3xl sm:text-5xl font-extralight flex-1 text-center text-[#707070]">
            Vergangene Gebotsstunden
          </h3>
          <div className="flex relative items-center justify-between">
            <button
              onClick={() => setIsFilter(!isFilter)}
              className="text-[#707070] z-20 flex justify-center items-center outline-none font-medium text-sm sm:w-auto px-4 py-2.5 text-center"
            >
              <h3 className="text-xm md:text-md 2xl:text-xl font-light">
                Filter
              </h3>
              <MdArrowDropDown size={30} />
            </button>
            {isFilter && (
              <div
                onMouseLeave={() => setIsFilter(!isFilter)}
                className=" absolute bottom-2 z-10 flex flex-col gap-4 top-10 left-auto right-0 w-max h-max px-4 py-2 bg-[#D9D9D9]"
              >
                <h3
                  onClick={() => {
                    setIsFilter(!isFilter);
                    setSortAscending(1);
                  }}
                  className={`text-xm md:text-md ${
                    sortAscending === 1 ? "text-blue-500" : null
                  } cursor-pointer hover:text-blue-500 2xl:text-xl font-light`}
                >
                  Vom Neuesten zum Ältesten
                </h3>
                <h3
                  onClick={() => {
                    setIsFilter(!isFilter);
                    setSortAscending(2);
                  }}
                  className={`text-xm md:text-md ${
                    sortAscending === 2 ? "text-blue-500" : null
                  } cursor-pointer hover:text-blue-500 2xl:text-xl font-light`}
                >
                  Vom Ältesten zum Neuesten
                </h3>
                <h3
                  onClick={() => {
                    setIsFilter(!isFilter);
                    setSortAscending(3);
                  }}
                  className={`text-xm md:text-md ${
                    sortAscending === 3 ? "text-blue-500" : null
                  } cursor-pointer hover:text-blue-500 2xl:text-xl font-light`}
                >
                  Preis hoch bis niedrig
                </h3>
                <h3
                  onClick={() => {
                    setIsFilter(!isFilter);
                    setSortAscending(4);
                  }}
                  className={`text-xm md:text-md ${
                    sortAscending === 4 ? "text-blue-500" : null
                  } cursor-pointer hover:text-blue-500 2xl:text-xl font-light`}
                >
                  Preis niedrig bis hoch
                </h3>
              </div>
            )}
          </div>
        </section>
        <section>
          {allItemsData?.length > 0 ? (
            allItemsData?.map((item, idx) => (
              <section>
                <section
                  key={idx}
                  className=" max-w-screen-xl lg:min-w-[751px] 2xl:min-w-[1280px] bg-[#98A3A6] cursor-pointer md:ml-72 md:mr-8 mx-4 flex-col md:flex-row min-[1900px]:mx-auto gap-2 md:gap-4 xl:gap-8"
                >
                  <div className="flex justify-between items-center gap-3  sm:gap-5 md:px-12 px-4 py-3 md:py-8">
                    <h3 className="text-2xl md:text-5xl 2xl:text-4xl font-bold">
                      {item.title}
                    </h3>
                    <h3 className="text-2xl text-[#17913A] md:text-5xl 2xl:text-7xl font-light">
                      {/* {liveItems[0]?.title} */}
                      Verkauft
                    </h3>
                  </div>
                  <div className="h-[1px] w-full bg-black"></div>
                  {item?.bids.length > 0 ? (
                    <div className="flex justify-between w-full items-start lg:items-center gap-4 flex-col lg:flex-row md:px-12 px-4 py-3 md:py-4">
                      <h3 className="text-md md:text-xl md:w-1/3 2xl:text-2xl font-light">
                        {item?.bids[0]?.name}
                      </h3>
                      <div className="flex justify-between items-center flex-1 gap-5">
                        <Link
                          to={`https://mail.google.com/mail/?view=cm&fs=1&to=${item?.bids[0]?.email}`}
                          target="_blank"
                        >
                          <h3 className="text-md lowercase underline hover:text-blue-900 md:text-xl 2xl:text-2xl font-light">
                            {item?.bids[0]?.email}
                          </h3>
                        </Link>

                        <h3 className="text-xl truncate text-[#17913A] md:text-3xl 2xl:text-4xl font-bold">
                          €
                          {new Intl.NumberFormat("en-US").format(
                            item?.bids[0]?.bidPrice
                          )}
                        </h3>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center w-full items-center md:px-12 px-4 py-3 md:py-4">
                      <h3 className="text-lg md:text-xl font-semibold">
                        keine Gebote
                      </h3>
                    </div>
                  )}
                </section>
                <section className="min-[1900px]:mx-auto flex justify-center items-center max-w-screen-xl mx-4 md:ml-72 my-4 ">
                  <button
                    onClick={() => {
                      setItemIdForBidsModal(item.id);
                      setBidDMOpen(!isBidDMOpen);
                    }}
                    className="text-[#707070] bg-[#D9D9D9] min-w-[120px] flex justify-center items-center outline-none font-medium text-sm w-full sm:w-auto px-16 py-2.5 text-center"
                  >
                    Angebotsdetails
                  </button>
                </section>
              </section>
            ))
          ) : (
            <section className="flex justify-center items-center md:pr-8 px-4 md:pl-72 my-16 w-full">
              <h3 className="text-lg md:text-xl font-semibold">
                keine Produkte
              </h3>
            </section>
          )}
        </section>
      </section>
    </section>
  );
};

export default SoldItems;
