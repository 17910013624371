import React, { useState } from "react";
import Hero from "../home/hero";
import Item from "../home/item";
import { useItemStore } from "../../store/useItemStore";
import NoData from "../reuse/NoData";
import BidDetails from "../home/BidDetails";

const Home = () => {
  const { liveItems } = useItemStore();
  return (
    <>
      <Hero />
      {liveItems?.length > 0 ? <Item /> : <NoData type={"Produkte"} />}
    </>
  );
};

export default Home;
