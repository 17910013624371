import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdArrowCircleLeft, MdArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";
export default function AdminLoginModal({ isLoginOpen, setIsLoginOpen }) {
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Benutzername wird benötigt"),
    password: Yup.string().required("Passwort wird benötigt"),
  });
  const [invalidError, setInvalidError] = useState(false);
  const initialValues = {
    username: "",
    password: "",
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // Simulate a login API call
    setSubmitting(true);
    console.log(values);
    if (values?.username === "admin" && values?.password === "admin12345") {
      setIsLoginOpen(true);
      localStorage.setItem("adminLogin", true);
    } else {
      resetForm();
      setInvalidError("Ungültige Anmeldeinformationen");
    }
    setSubmitting(false);
  };
  return (
    <>
      <Transition appear show={!isLoginOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => console.log("kuch bi")}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl min-w-max flex flex-col min-w-xl transform overflow-hidden rounded-2xl bg-[#707070] py-16 text-left align-middle shadow-xl transition-all">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form className="w-full capitalize flex justify-center items-center px-8 flex-col">
                        <div className="relative z-0 flex items-center w-full mb-5 group">
                          <h3 className="text-white leading-5 sm:leading-none text-2xl md:text-3xl 2xl:text-4xl min-[2000px]:text-7xl flex-1 text-center">
                            Administration
                          </h3>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <label
                            htmlFor="username"
                            className=" text-sm text-[#FFFFFF]"
                          >
                            Benutzername
                          </label>
                          <Field
                            type="text"
                            name="username"
                            id="username"
                            className="block py-2.5  w-full text-sm bg-[#D9D9D9] outline-none px-3 text-[#707070]"
                            placeholder=" "
                          />

                          <ErrorMessage
                            name="username"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <label
                            htmlFor="password"
                            className=" text-sm text-[#FFFFFF]"
                          >
                            Passwort
                          </label>
                          <Field
                            type="password"
                            name="password"
                            id="password"
                            className="block py-2.5  w-full text-sm bg-[#D9D9D9] outline-none px-3 text-[#707070]"
                            placeholder=" "
                          />
                          {invalidError && (
                            <div className="text-red-500 text-sm">
                              {invalidError}
                            </div>
                          )}
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <button
                          type="submit"
                          className="text-[#707070] bg-[#D9D9D9] min-w-[120px] flex justify-center items-center outline-none font-medium text-sm w-full sm:w-auto px-16 py-2.5 text-center"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <AiOutlineLoading3Quarters
                              color={""}
                              size={28}
                              className="animate-spin"
                            />
                          ) : (
                            "Anmelden"
                          )}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
