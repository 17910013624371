import React, { useState } from "react";
import NoData from "../reuse/NoData";
import { useItemStore } from "../../store/useItemStore";
import { image_base } from "../../libs/backendAPIs";
import { useNavigate } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";

const CanceledItems = ({ setBidDMOpen, isBidDMOpen }) => {
  const navigate = useNavigate();
  const { canceledItems } = useItemStore();
  const [sortAscending, setSortAscending] = useState(true);
  const [isFilter, setIsFilter] = useState(false);
  return (
    <section className="flex capitalize flex-col justify-center items-center gap-6">
      <section className="">
        <section className="relative flex justify-center gap-12 flex-col sm:flex-row items-center w-full md:pr-8 px-4 md:pl-72 my-16">
          <h3 className="text-3xl sm:text-5xl font-extralight flex-1 text-center text-[#707070]">
            Stornierte Gebotsstunden
          </h3>
          <div className="flex relative items-center justify-between">
            <button
              onClick={() => setIsFilter(!isFilter)}
              className="text-[#707070] z-20 flex justify-center items-center outline-none font-medium text-sm sm:w-auto px-4 py-2.5 text-center"
            >
              <h3 className="text-xm md:text-md 2xl:text-xl font-light">
                Filter
              </h3>
              <MdArrowDropDown size={30} />
            </button>
            {isFilter && (
              <div
                onMouseLeave={() => setIsFilter(!isFilter)}
                className=" absolute bottom-2 z-10 flex flex-col gap-4 top-10 left-auto right-0 w-max h-max px-4 py-2 bg-[#D9D9D9]"
              >
                <h3
                  onClick={() => {
                    setIsFilter(!isFilter);
                    setSortAscending(true);
                  }}
                  className={`text-xm md:text-md ${
                    sortAscending ? "text-blue-500" : null
                  } cursor-pointer hover:text-blue-500 2xl:text-xl font-light`}
                >
                  Vom Neuesten zum Ältesten
                </h3>
                <h3
                  onClick={() => {
                    setIsFilter(!isFilter);
                    setSortAscending(false);
                  }}
                  className={`text-xm md:text-md ${
                    !sortAscending ? "text-blue-500" : null
                  } cursor-pointer hover:text-blue-500 2xl:text-xl font-light`}
                >
                  Vom Ältesten zum Neuesten
                </h3>
              </div>
            )}
          </div>
        </section>
        <section
          className={` flex ${
            sortAscending ? " flex-col-reverse" : "flex-col"
          }`}
        >
          {canceledItems?.length > 0 ? (
            canceledItems?.map((item, idx) => (
              <>
                <section
                  key={idx}
                  className=" max-w-screen-xl lg:min-w-[751px] 2xl:min-w-[1280px] bg-[#98A3A6] my-8 cursor-pointer md:ml-72 md:mr-8 mx-4 flex-col md:flex-row min-[1900px]:mx-auto gap-2 md:gap-4 xl:gap-8"
                >
                  <div className="flex justify-between gap-5 items-center md:px-12 px-4 py-3 md:py-8">
                    <h3 className="text-2xl md:text-4xl font-bold">
                      {item.title}
                    </h3>
                    <h3 className="text-2xl text-[#94282B] md:text-4xl font-light">
                      Abgesagt
                    </h3>
                  </div>
                </section>
              </>
            ))
          ) : (
            <section className="flex justify-center items-center md:pr-8 px-4 md:pl-72 my-16 w-full">
              <h3 className="text-lg md:text-xl font-semibold">No Items</h3>
            </section>
          )}
        </section>
      </section>
    </section>
  );
};

export default CanceledItems;
