import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MdModeEdit } from "react-icons/md";
import {
  addItem,
  getAllItems,
  image_base,
  updateItem,
  updateItemByFieldName,
} from "../../libs/backendAPIs";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useItemStore } from "../../store/useItemStore";
import "flatpickr/dist/themes/airbnb.css";

import Flatpickr from "react-flatpickr";
import NoData from "../reuse/NoData";
import { useBidStore } from "../../store/useBidStore";
import { FaMinus, FaPlus } from "react-icons/fa";

const Dashboard = ({ isBidDMOpen, setBidDMOpen, setItemIdForBidsModal }) => {
  const [minDate, setMinDate] = useState(new Date());
  const { liveItems, updateLiveItems } = useItemStore();
  const { allBidsByItemID } = useBidStore();
  const [Emode, setEMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(null);
  useEffect(() => {
    const currentDate = new Date();
    setMinDate(currentDate);
    // console.log(showImage);
  }, [updateLiveItems, liveItems]);
  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      setSubmitting(true);
      console.log(values);
      // Parse the bidTime into a JavaScript Date object
      const bidTime = new Date(values.bidTime);

      // Calculate the difference in milliseconds between the current date and bidTime
      const currentTime = new Date();
      const differenceMs = bidTime - currentTime;

      // Calculate days, hours, minutes, and seconds from milliseconds
      const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (differenceMs % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((differenceMs % (1000 * 60)) / 1000);

      // Format the difference into DD:HH:MM:SS string
      const formattedDifference = `${days}:${hours}:${minutes}:${seconds}`;
      // values.image = showImage;
      values.bidTime = formattedDifference;
      const res = await addItem({ ...values, image: showImage });
      console.log(res);
      if (res?.status === 200) {
        console.log("res", res.data);
        toast.success(res?.data?.message, {
          position: "top-center",
        });
      }
      setShowImage(null);
      resetForm();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      resetForm();
      console.log(error);
    }
  };
  const onUpdate = async (values, { resetForm, setSubmitting }) => {
    try {
      setSubmitting(true);
      console.log(values);
      // Parse the bidTime into a JavaScript Date object
      const bidTime = new Date(values.bidTime);

      // Calculate the difference in milliseconds between the current date and bidTime
      const currentTime = new Date();
      const differenceMs = bidTime - currentTime;

      // Calculate days, hours, minutes, and seconds from milliseconds
      const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (differenceMs % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((differenceMs % (1000 * 60)) / 1000);

      // Format the difference into DD:HH:MM:SS string
      const formattedDifference = `${days}:${hours}:${minutes}:${seconds}`;
      values.bidTime = formattedDifference;
      const res = await updateItem(values, liveItems[0]?.id);
      console.log(res);
      if (res?.status === 200) {
        console.log("res", res.data);
        toast.success(res?.data?.message, {
          position: "top-center",
        });
      }
      resetForm();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      resetForm();
      console.log(error);
    }
  };
  const formatBidTime = (bidTimeStr) => {
    const [days, hours, mins, secs] = bidTimeStr.split(":").map(Number);
    const formattedBidTime = [];

    if (days > 0) formattedBidTime.push(`${days} Tag${days > 1 ? "e" : ""}`);
    if (hours > 0)
      formattedBidTime.push(`${hours} Stunde${hours > 1 ? "n" : ""}`);
    if (mins > 0) formattedBidTime.push(`${mins} Minute${mins > 1 ? "n" : ""}`);
    if (secs > 0)
      formattedBidTime.push(`${secs} Sekunde${secs > 1 ? "n" : ""}`);

    return formattedBidTime.join(" ");
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Titel erforderlich"),
    description: Yup.string()
      .required("Beschreibung erforderlich")
      .min(10, "Die Beschreibung muss mehr als 10 Wörter enthalten."),
    bidPrice: Yup.number()
      .positive("Das Gebot muss größer als null sein.")
      .required("Gebotspreis erforderlich"),
    priceGap: Yup.number()
      .positive("Die Preisspanne muss größer als null sein.")
      .required("Preisspanne erforderlich"),
    bidTime: Yup.string().required("Gebotszeit erforderlich"),
  });
  return (
    <section className="px-8 sm:px-0">
      {liveItems?.length > 0 && !Emode ? (
        <>
          <section className="min-[1900px]:mx-auto flex justify-center items-center max-w-screen-xl md:mr-8 md:ml-72 mx-4 my-16 mb-20">
            <h3 className="text-3xl sm:text-5xl font-extralight text-center text-[#707070]">
              Live-Gebote
            </h3>
          </section>
          <section className="max-w-screen-xl flex justify-between items-center bg-[#98A3A6] px-4 pt-2 sm:pt-8 sm:px-16 mx-4 md:ml-72 md:mr-8 min-[1900px]:mx-auto gap-2 md:gap-4 xl:gap-8">
            <h3 className="text-2xl md:text-5xl 2xl:text-7xl font-bold">
              {liveItems[0]?.title}
              {/* Title */}
            </h3>
            {/* <div
              onClick={() => setEMode(!Emode)}
              className=" bg-[#C6BDB4] hover:bg-orange-300 p-3 cursor-pointer rounded-full"
            >
              <MdModeEdit className=" text-black" size={20} />
            </div> */}
          </section>
          <section className="max-w-screen-xl grid grid-cols-1 lg:grid-cols-2 bg-[#98A3A6] px-4 py-2 sm:py-8 sm:px-16 mx-4 md:ml-72 md:mr-8 min-[1900px]:mx-auto gap-2 md:gap-4 xl:gap-8">
            <div className="flex w-full  md:gap-20 gap-10  justify-between flex-col items-start">
              <div className="flex w-full md:gap-4 gap-2  justify-between flex-col items-start">
                <p className="text-base md:text-lg whitespace-pre-wrap">
                  {liveItems[0]?.description}
                </p>
              </div>
              <div>
                <h5 className="text-xl md:text-3xl flex flex-col gap-2 md:text-medium font-bold">
                  Verbleibende Gebotszeit:
                  <span className=" mx-3 text-[#94282B] text-2xl md:text-4xl font-light">
                    {" "}
                    {formatBidTime(liveItems[0]?.bidTime)}
                  </span>
                </h5>
              </div>
            </div>
            <div className=" flex flex-col gap-2 md:gap-4 justify-start items-end">
              <img
                alt="content"
                class="object-contain object-center h-full w-full"
                src={image_base + "/" + liveItems[0]?.image}
              />
              {/* <div className="h-full bg-[#D9D9D9] w-full"></div> */}
            </div>
          </section>
          <section className="min-[1900px]:mx-auto flex justify-center items-center max-w-screen-xl mx-4 md:ml-72 mt-8 ">
            <button
              onClick={() => {
                setItemIdForBidsModal(liveItems[0]?.id);
                setBidDMOpen(!isBidDMOpen);
              }}
              className="text-[#707070] bg-[#D9D9D9] min-w-[120px] flex justify-center items-center outline-none font-medium text-sm w-full sm:w-auto px-16 py-2.5 text-center"
            >
              Gebotsdetails
            </button>
          </section>
          <section className="min-[1900px]:mx-auto flex gap-4 justify-center items-center max-w-screen-xl mx-4 md:ml-72 my-8 ">
            <button
              onClick={async () => {
                try {
                  setLoading("sold");
                  const res = await updateItemByFieldName({
                    fieldName: "status",
                    itemId: liveItems[0]?.id,
                    value: "sold",
                  });
                  if (res?.status === 200) {
                    console.log("res", res.data);
                    toast.success(res?.data?.message, {
                      position: "top-center",
                    });
                  }
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                  console.log(error);
                }
              }}
              className="text-white font-bold  bg-[#17913A] hover:bg-green-800 min-w-[120px] flex justify-center items-center focus:ring-4 focus:outline-none focus:ring-green-300 text-sm w-full sm:w-auto px-4 sm:px-16 py-2.5 text-center"
            >
              {loading === "sold" ? (
                <AiOutlineLoading3Quarters
                  color={""}
                  size={28}
                  className="  animate-spin"
                />
              ) : (
                " Ausverkauft"
              )}
            </button>
            <button
              onClick={async () => {
                try {
                  setLoading("cancel");
                  const res = await updateItemByFieldName({
                    fieldName: "status",
                    itemId: liveItems[0]?.id,
                    value: "cancel",
                  });
                  if (res?.status === 200) {
                    console.log("res", res.data);
                    toast.success(res?.data?.message, {
                      position: "top-center",
                    });
                  }
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                  console.log(error);
                }
              }}
              className="text-white font-bold bg-[#94282B] hover:bg-red-800 min-w-[120px] flex justify-center items-center focus:ring-4 focus:outline-none focus:ring-red-300 text-sm w-full sm:w-auto px-4 sm:px-16 py-2.5 text-center"
            >
              {loading === "cancel" ? (
                <AiOutlineLoading3Quarters
                  color={""}
                  size={28}
                  className="  animate-spin"
                />
              ) : (
                "Abgesagt"
              )}
            </button>
          </section>
        </>
      ) : (
        <>
          <section className="min-[1900px]:mx-auto flex justify-center items-center max-w-screen-xl md:mr-8 mx-4 md:ml-72 my-16 mb-20">
            <h3 className="text-3xl sm:text-5xl font-extralight text-center text-[#707070]">
              Listen Sie Ihre Immobilie auf
            </h3>
          </section>
          <section className="min-[1900px]:mx-auto flex justify-between items-center max-w-screen-xl md:mr-8 md:ml-72 mx-4 my-16 mb-10">
            <h3 className="text-2xl sm:text-3xl font-extralight text-[#707070]">
              Fügen Sie die Details Ihrer Immobilie hinzu
            </h3>
            {/* {liveItems?.length > 0 && (
              <div
                onClick={() => setEMode(!Emode)}
                className=" bg-[#D9D9D9CC] hover:bg-orange-300 p-3 cursor-pointer rounded-full"
              >
                <MdModeEdit className=" text-black" size={20} />
              </div>
            )} */}
          </section>
          <Formik
            initialValues={{
              title: (liveItems.length > 0 && liveItems[0].title) || "",
              description:
                (liveItems.length > 0 && liveItems[0].description) || "",
              bidPrice: (liveItems.length > 0 && liveItems[0].bidPrice) || 0,
              priceGap: (liveItems.length > 0 && liveItems[0].priceGap) || 0,
              bidTime: "",
              image: null,
            }}
            validationSchema={validationSchema}
            onSubmit={liveItems.length > 0 ? onUpdate : onSubmit}
          >
            {({ errors, values, touched, isSubmitting, setFieldValue }) => (
              <Form className="w-full">
                <section className="max-w-screen-xl grid grid-cols-1 lg:grid-cols-2 bg-[#98A3A6] px-4 py-2 sm:py-8 sm:px-16 md:ml-72 md:mr-8 mx-4 min-[1900px]:mx-auto gap-2 md:gap-4 xl:gap-8">
                  <div className="">
                    <div className="relative z-0 w-full mb-5 group">
                      <label
                        htmlFor="title"
                        className=" text-sm text-[#FFFFFF]"
                      >
                        Titel
                      </label>
                      <Field
                        type="text"
                        name="title"
                        id="title"
                        className="block py-2.5  w-full text-sm bg-[#D9D9D9] outline-none px-3 text-[#707070]"
                        placeholder=" "
                      />

                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                      <label
                        htmlFor="description"
                        className=" text-sm text-[#FFFFFF]"
                      >
                        Beschreibung
                      </label>
                      <Field
                        as="textarea"
                        rows={8}
                        name="description"
                        id="description"
                        className="block py-2.5  w-full text-sm bg-[#D9D9D9] outline-none px-3 text-[#707070]"
                        placeholder=" "
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div className="mb-5 flex gap-4">
                      <div className="relative z-0 w-full group">
                        <label
                          htmlFor="bidPrice"
                          className=" text-sm text-[#FFFFFF]"
                        >
                          Gebotspreis
                        </label>
                        <Field
                          type="text"
                          name="bidPrice"
                          id="bidPrice"
                          className="block py-2.5  w-full text-sm appearance-none bg-[#D9D9D9] outline-none px-3 text-[#707070]"
                          placeholder=" "
                        />
                        <div className="flex flex-col gap-2 w-12 cursor-pointer justify-center font-bold text-[#707070] absolute -right-3 top-6 rounded-xl items-center py-2">
                          <FaPlus
                            onClick={() => {
                              console.log(values.bidPrice);
                              !(values.bidPrice === "") &&
                                setFieldValue(
                                  "bidPrice",
                                  parseInt(values?.bidPrice) + 100
                                );
                            }}
                            size={10}
                            className="hover:text-gray-500"
                          />
                          <FaMinus
                            onClick={() => {
                              const newBidPrice =
                                parseInt(values?.bidPrice) - 100;
                              if (newBidPrice > 0) {
                                setFieldValue("bidPrice", newBidPrice);
                              }
                            }}
                            className="hover:text-gray-500"
                            size={10}
                          />
                        </div>
                        <ErrorMessage
                          name="bidPrice"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="relative z-0 w-full group">
                        <label
                          htmlFor="priceGap"
                          className=" text-sm text-[#FFFFFF]"
                        >
                          Preisspanne
                        </label>
                        <Field
                          type="text"
                          name="priceGap"
                          id="priceGap"
                          className="block py-2.5  w-full text-sm bg-[#D9D9D9] appearance-none outline-none px-3 text-[#707070]"
                          placeholder=" "
                        />
                        <div className="flex flex-col gap-2 z-30 w-12 cursor-pointer justify-center font-bold text-[#707070] absolute -right-3 top-6 rounded-xl items-center py-2">
                          <FaPlus
                            onClick={() => {
                              !(values.priceGap === "") &&
                                setFieldValue(
                                  "priceGap",
                                  parseInt(values?.priceGap) + 10
                                );
                            }}
                            size={10}
                            className="hover:text-gray-500"
                          />
                          <FaMinus
                            onClick={() => {
                              const newPriceGap =
                                parseInt(values?.priceGap) - 10;
                              if (newPriceGap > 0) {
                                setFieldValue("priceGap", newPriceGap);
                              }
                            }}
                            className="hover:text-gray-500"
                            size={10}
                          />
                        </div>
                        <ErrorMessage
                          name="priceGap"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>
                    {liveItems.length > 0 && (
                      <div className="relative z-0 w-full mb-5 group">
                        <h5 className="text-sm md:text-medium font-bold">
                          Gebotszeit
                          <span className=" mx-3">
                            {" "}
                            {formatBidTime(liveItems[0]?.bidTime)}
                          </span>
                        </h5>
                      </div>
                    )}
                    <div className="relative z-0 w-full mb-5 group">
                      <label
                        htmlFor="bidTime"
                        className=" text-sm text-[#FFFFFF]"
                      >
                        Gebotszeit (Tage:Stunden:Minuten:Sekunden)
                      </label>
                      <Flatpickr
                        data-enable-time
                        options={{
                          enableTime: true,
                          minDate: minDate,
                          // defaultDate: minDate,
                        }}
                        value={values?.bidTime}
                        placeholder=""
                        className="block py-2.5  w-full text-sm bg-[#D9D9D9] outline-none px-3 text-[#707070]"
                        onChange={([date]) => {
                          if (date > minDate) {
                            console.log(date);
                            setFieldValue("bidTime", date);
                          }
                        }}
                      />

                      <ErrorMessage
                        name="bidTime"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col pb-5 pt-[30px]">
                    <div className="w-full mb-5 relative">
                      <div className="flex items-center justify-center h-full">
                        <label htmlFor="image" className="cursor-pointer">
                          <div className="text-[#707070] bg-[#D9D9D9] min-w-[120px] flex justify-center items-center outline-none font-medium text-sm w-full sm:w-auto px-16 py-2.5 text-center">
                            Bild hochladen
                          </div>
                          <Field
                            id="image"
                            name="image"
                            type="file"
                            className="hidden"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              setShowImage(file);
                              // setFieldValue("image", file);
                              // event.currentTarget.value = null;
                            }}
                            validate={(value) => {
                              let error;
                              if (!(liveItems.length > 0) && !showImage) {
                                error = "Image is required";
                              }
                              return error;
                            }}
                          />
                          <ErrorMessage
                            name="image"
                            component="div"
                            className="text-red-600 text-xs mt-1"
                          />
                        </label>
                      </div>
                    </div>
                    {liveItems?.length > 0 ? (
                      <div className=" h-full">
                        <img
                          alt="content"
                          className="object-cover object-center h-full w-full"
                          src={image_base + "/" + liveItems[0]?.image}
                        />
                      </div>
                    ) : showImage ? (
                      <div className=" h-full">
                        <img
                          alt="content"
                          className="object-cover object-center h-full w-full"
                          src={URL.createObjectURL(showImage)}
                        />
                      </div>
                    ) : (
                      <div className="h-full bg-[#D9D9D9]"></div>
                    )}
                  </div>
                </section>
                <section className="min-[1900px]:mx-auto flex justify-center items-center max-w-screen-xl mx-4 md:ml-72 mt-8 my-16">
                  <button
                    type="submit"
                    className="text-[#707070] bg-[#D9D9D9] min-w-[120px] flex justify-center items-center outline-none font-medium text-sm w-full sm:w-auto px-16 py-2.5 text-center"
                  >
                    {isSubmitting ? (
                      <AiOutlineLoading3Quarters
                        color={""}
                        size={28}
                        className="  animate-spin"
                      />
                    ) : (
                      "Absenden"
                    )}
                  </button>
                </section>
              </Form>
            )}
          </Formik>
        </>
      )}
    </section>
  );
};

export default Dashboard;
