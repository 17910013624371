import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const AdminDashboard = ({ setIsLoginOpen }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // Function to determine if a sidebar link is active
  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      {/* Sidebar toggle button */}
      <button
        onClick={toggleSidebar}
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center capitalize p-2 mt-2 ms-3 text-sm text-[#707070] rounded-lg md:hidden focus:outline-none"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      {/* Sidebar */}
      <aside
        id="default-sidebar"
        className={`fixed top-0 capitalize left-0 z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? "" : "-translate-x-full"
        } md:translate-x-0 bg-[#D9D9D9]`}
        aria-label="Sidebar"
      >
        <div className="h-full py-4 overflow-y-auto flex flex-col gap-5 sm:gap-16 xl:gap-32 ">
          <img src="/adminLogo.png" className=" w-full h-auto" alt="logo" />
          <ul className="space-y-2 font-normal">
            <li>
              <Link
                to="/admin"
                className={`flex items-center p-2 ${
                  isActiveLink("/admin") ? "bg-[#98A3A6] text-[#D9D9D9]" : null
                } text-[#707070] px-8 hover:text-[#D9D9D9] hover:bg-[#98A3A6] outline-none group `}
              >
                <svg
                  className="w-5 h-5 transition duration-75"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21"
                >
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Dashboard</span>
              </Link>
            </li>

            <li>
              <Link
                to="soldItems"
                className={`flex items-center ${
                  isActiveLink("/admin/soldItems")
                    ? "bg-[#98A3A6] text-[#D9D9D9]"
                    : null
                }  p-2 text-[#707070] px-8 hover:text-[#D9D9D9] hover:bg-[#98A3A6] outline-none group ${
                  isActiveLink("/soldItems") && "bg-[#98A3A6] text-[#D9D9D9]"
                }`}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 transition duration-75"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 20"
                >
                  <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Verkaufte Artikel
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="canceledItems"
                className={`flex items-center ${
                  isActiveLink("/admin/canceledItems")
                    ? "bg-[#98A3A6] text-[#D9D9D9]"
                    : null
                }  p-2 text-[#707070] px-8 hover:text-[#D9D9D9] hover:bg-[#98A3A6] outline-none group ${
                  isActiveLink("/canceledItems") &&
                  "bg-[#98A3A6] text-[#D9D9D9]"
                }`}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 transition duration-75"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 20"
                >
                  <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Stornierte Artikel
                </span>
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  setIsLoginOpen(false);
                  localStorage.removeItem("adminLogin");
                }}
                className={`flex w-full items-center p-2 text-[#707070] px-8 hover:text-[#D9D9D9] hover:bg-[#98A3A6] outline-none group ${
                  isActiveLink("/signout") && "bg-[#98A3A6] text-[#D9D9D9]"
                }`}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 transition duration-75"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                  />
                </svg>
                <span className="ms-3 whitespace-nowrap">Abmelden</span>
              </button>
            </li>
          </ul>
        </div>
      </aside>
      {/* Overlay to close sidebar when clicked outside */}
      {isSidebarOpen && (
        <div
          className="fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50"
          onClick={toggleSidebar}
        ></div>
      )}
      <Outlet />
    </>
  );
};

export default AdminDashboard;
