import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useBidStore } from "../../store/useBidStore";

export default function BidDetailModal({
  isBidDMOpen,
  setBidDMOpen,
  itemIdForBidsModal,
}) {
  const [bidsData, setBidsData] = useState([]);
  const { allBids } = useBidStore();

  useEffect(() => {
    const itemBids =
      allBids?.filter((bid) => bid.itemId === itemIdForBidsModal) || [];
    // Sort by bidPrice descending, then by createdAt ascending
    itemBids.sort((a, b) => {
      if (parseInt(b.bidPrice) === parseInt(a.bidPrice)) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      return parseInt(b.bidPrice) - parseInt(a.bidPrice);
    });
    setBidsData(itemBids);
    console.log(itemBids);
  }, [itemIdForBidsModal, allBids]);

  return (
    <>
      <Transition appear show={isBidDMOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setBidDMOpen(!isBidDMOpen)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl flex flex-col min-w-xl transform overflow-hidden rounded-2xl bg-[#D9D9D9] py-6 text-left align-middle shadow-xl transition-all">
                  <div className="w-full flex items-center px-8">
                    <h3 className="text-xl flex-1 sm:text-3xl font-extralight my-4 text-center text-[#707070]">
                    Gebote
                    </h3>
                    <IoMdClose
                      onClick={() => setBidDMOpen(!isBidDMOpen)}
                      size={30}
                    />
                  </div>
                  <section className="flex capitalize flex-col">
                    {bidsData.length > 0 ? (
                      bidsData.map((item, idx) => (
                        <div
                          className={`flex w-full ${
                            idx % 2 === 0 ? "bg-transparent" : "bg-white"
                          } relative justify-between items-center px-16 py-5`}
                          key={item.id}
                        >
                          <h4>{item.name}</h4>
                          <div className="w-[1px] h-full bg-black absolute top-0 bottom-0 left-[50%]"></div>
                          <h6>
                            {" "}
                            €
                            {new Intl.NumberFormat("en-US").format(
                              item.bidPrice
                            )}
                          </h6>
                        </div>
                      ))
                    ) : (
                      <h3 className="text-lg text-center w-full md:text-xl font-semibold">
                        No Bids
                      </h3>
                    )}
                  </section>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
